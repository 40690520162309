export function formatDate(date: string): string {
  if (date) {
    return new Date(date).toLocaleString();
  }

  return "-";
}

export function formatDateOnly(date: string): string {
  if (date) {
    return new Date(date).toLocaleDateString();
  }

  return "-";
}

export function dateToLocalDateTime(date: string): string {
  if (date) {
    const d: Date = new Date(date);
    const temp: Date = new Date(d.getTime() - d.getTimezoneOffset() * 60000);
    return temp.toISOString().slice(0, -1);
  }
  return "";
}

export function localDateTimeToDate(date: string): string {
  if (date) {
    return new Date(date).toISOString();
  }
  return "";
}

export function dateToISODate(date: string): string {
  if (date) {
    return new Date(date).toISOString().split("T")[0];
  }
  return "";
}
