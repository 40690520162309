import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import {
  apiCreateUser,
  apiGetPersonas,
  apiGetUser,
  apiUpdateUser,
} from "../api";
import { Persona, UserData } from "../types";

interface UserDialogProps {
  open: boolean;
  userId: number;
  closeHandler: (ok: boolean) => void;
}

export default function UserDialog(props: UserDialogProps): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [isStoring, setIsStoring] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [personas, setPersonas] = useState<Persona[]>([]);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [persona, setPersona] = useState("");

  useEffect(() => {
    apiGetPersonas()
      .then((data) => {
        setPersonas(data);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    if (!props.open) return;

    if (props.userId) {
      setIsLoading(true);
      apiGetUser(props.userId)
        .then((data) => {
          setIsLoading(false);
          setErrorMessage("");

          setName(data.name);
          setEmail(data.email);
          setPersona(data.persona);
        })
        .catch((e) => {
          setIsLoading(false);
          setErrorMessage(e.message);
        });
    } else {
      setErrorMessage("");

      setName("");
      setEmail("");
      setPersona("");
    }
  }, [props.open, props.userId]);

  const save = () => {
    const userData: UserData = { name, email, persona };

    setIsStoring(true);
    if (!props.userId) {
      apiCreateUser(userData)
        .then((result) => {
          setIsStoring(false);

          if (result) {
            setErrorMessage(JSON.stringify(result.errors));
          } else {
            setErrorMessage("");

            props.closeHandler(true);
          }
        })
        .catch((e) => {
          setIsStoring(false);
          setErrorMessage(e.message);
        });
    } else {
      apiUpdateUser(props.userId, userData)
        .then((result) => {
          setIsStoring(false);

          if (result) {
            setErrorMessage(JSON.stringify(result.errors));
          } else {
            setErrorMessage("");

            props.closeHandler(true);
          }
        })
        .catch((e) => {
          setIsStoring(false);
          setErrorMessage(e.message);
        });
    }
  };

  return (
    <Dialog open={props.open} onClose={() => props.closeHandler(false)}>
      <DialogTitle>
        {props.userId ? "Edit User " + props.userId : "Create User"}
      </DialogTitle>
      <DialogContent dividers>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <Backdrop open={isLoading || isStoring}>
          <CircularProgress />
        </Backdrop>
        <TextField
          fullWidth
          size="small"
          margin="normal"
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          fullWidth
          size="small"
          margin="normal"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <FormControl fullWidth size="small" margin="normal">
          <InputLabel id="persona-label">Persona</InputLabel>
          <Select
            fullWidth
            label="Persona"
            labelId="persona-label"
            value={persona}
            onChange={(e) => setPersona(e.target.value as string)}
          >
            {personas.map((x) => (
              <MenuItem value={x.name}>{x.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={save}>
          Save
        </Button>
        <Button color="primary" onClick={() => props.closeHandler(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
