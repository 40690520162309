import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { ReactElement } from "react";

interface ConfirmDialogProps {
  open: boolean;
  title: string;
  message: string;
  closeHandler: (value: boolean) => void;
}

export default function ConfirmDialog(props: ConfirmDialogProps): ReactElement {
  return (
    <Dialog maxWidth="sm" fullWidth={true} open={props.open}>
      <DialogTitle>{props.title}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={() => props.closeHandler(false)}>
          Cancel
        </Button>

        <Button color="primary" onClick={() => props.closeHandler(true)}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
