import AddIcon from "@mui/icons-material/Add";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import WarningIcon from "@mui/icons-material/Warning";
import WifiIcon from "@mui/icons-material/Wifi";
import {
  Alert,
  Box,
  Button,
  FormControl,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { green, grey } from "@mui/material/colors";
import { ReactElement, useEffect, useState } from "react";
import {
  apiDeleteRequest,
  apiGetProjects,
  apiGetRequestCount,
  apiGetRequests,
  apiGetVisitTypes,
} from "../api";
import ConfirmDialog from "../Dialogs/ConfirmDialog";
import RequestDialog from "../Dialogs/RequestDialog";
import { Project, Request, RequestOrder, VisitType } from "../types";
import { formatDate } from "../utils";
import TableDateCell from "./TableDateCell";
import TableFilterCell from "./TableFilterCell";
import TableHeaderCell from "./TableHeaderCell";

interface RequestsTableProps {
  archive?: boolean;
  isUserAdmin: boolean;
}

export default function RequestsTable(props: RequestsTableProps): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [projects, setProjects] = useState<Project[]>([]);
  const [visitTypes, setVisitTypes] = useState<VisitType[]>([]);

  const [employeeNameFilter, setEmployeeNameFilter] = useState("");
  const [visitTypeFilter, setVisitTypeFilter] = useState("");
  const [jobNoFilter, setJobNoFilter] = useState("");
  const [entryDateFrom, setEntryDateFrom] = useState("");
  const [entryDateTo, setEntryDateTo] = useState("");

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [order, setOrder] = useState<RequestOrder>(
    props.archive
      ? RequestOrder.ENTRY_DATE_TIME_DESC
      : RequestOrder.ENTRY_DATE_TIME_ASC,
  );

  const [requests, setRequests] = useState<Request[]>([]);

  const [selectedRequestId, setSelectedRequestId] = useState(0);

  const [isCreateEditDialogOpen, setIsCreateEditDialogOpen] = useState(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);

  useEffect(() => {
    apiGetProjects()
      .then((data) => {
        setProjects(data);
      })
      .catch(() => {
        setProjects([]);
      });

    apiGetVisitTypes()
      .then((data) => {
        setVisitTypes(data);
      })
      .catch(() => {
        setVisitTypes([]);
      });
  }, []);

  useEffect(() => {
    if (isCreateEditDialogOpen || isConfirmDeleteDialogOpen) return;

    apiGetRequestCount({
      isActive: !props.archive,
      employeeNameContains: employeeNameFilter,
      visitTypeMatches: visitTypeFilter,
      jobNoMatches: jobNoFilter,
      entryDateTimeFrom: entryDateFrom,
      entryDateTimeTo: entryDateTo,
    })
      .then((data) => {
        setCount(data);
      })
      .catch(() => {
        setCount(0);
      });
  }, [
    props.archive,
    employeeNameFilter,
    visitTypeFilter,
    jobNoFilter,
    entryDateFrom,
    entryDateTo,
    isCreateEditDialogOpen,
    isConfirmDeleteDialogOpen,
  ]);

  useEffect(() => {
    if (isCreateEditDialogOpen || isConfirmDeleteDialogOpen) return;

    setIsLoading(true);
    apiGetRequests(
      {
        isActive: !props.archive,
        employeeNameContains: employeeNameFilter,
        visitTypeMatches: visitTypeFilter,
        jobNoMatches: jobNoFilter,
        entryDateTimeFrom: entryDateFrom,
        entryDateTimeTo: entryDateTo,
      },
      order,
      page * rowsPerPage,
      rowsPerPage,
    )
      .then((data) => {
        setIsLoading(false);
        setErrorMessage("");
        setRequests(data);
      })
      .catch((e) => {
        setIsLoading(false);
        setErrorMessage(e.message);
        setRequests([]);
      });
  }, [
    props.archive,
    employeeNameFilter,
    visitTypeFilter,
    jobNoFilter,
    entryDateFrom,
    entryDateTo,
    order,
    page,
    rowsPerPage,
    isCreateEditDialogOpen,
    isConfirmDeleteDialogOpen,
  ]);

  const clearFilters = () => {
    setEmployeeNameFilter("");
    setVisitTypeFilter("");
    setJobNoFilter("");
    setEntryDateFrom("");
    setEntryDateTo("");
  };

  const handleCreateButtonClick = () => {
    setSelectedRequestId(0);
    setIsCreateEditDialogOpen(true);
  };

  const handleEditButtonClick = (requestNumber: number) => {
    setSelectedRequestId(requestNumber);
    setIsCreateEditDialogOpen(true);
  };

  const handleDeleteButtonClick = (requestNumber: number) => {
    setSelectedRequestId(requestNumber);
    setIsConfirmDeleteDialogOpen(true);
  };

  const handleConfirmDialogClose = (confirm: boolean) => {
    if (confirm) {
      apiDeleteRequest(selectedRequestId)
        .then(() => {
          setErrorMessage("");
          setIsConfirmDeleteDialogOpen(false);
        })
        .catch((e) => {
          setErrorMessage(e.message);
          setIsConfirmDeleteDialogOpen(false);
        });
    } else {
      setIsConfirmDeleteDialogOpen(false);
    }
  };

  return (
    <>
      <RequestDialog
        isUserAdmin={props.isUserAdmin}
        requestId={selectedRequestId}
        open={isCreateEditDialogOpen}
        closeHandler={() => setIsCreateEditDialogOpen(false)}
      />
      <ConfirmDialog
        open={isConfirmDeleteDialogOpen}
        title="Delete request"
        message="Are you sure you want to delete this request?"
        closeHandler={handleConfirmDialogClose}
      />
      <Table size="small">
        <TableHead style={{ backgroundColor: grey[100] }}>
          <TableRow key="headerrow">
            <TableCell />
            <TableCell>Actions</TableCell>
            <TableHeaderCell
              title="ID"
              order={order}
              orderAsc={RequestOrder.REQUEST_NUMBER_ASC}
              orderDesc={RequestOrder.REQUEST_NUMBER_DESC}
              onOrderChange={(o) => setOrder(o)}
            />
            <TableHeaderCell
              title="Assigned By"
              order={order}
              orderAsc={RequestOrder.EMPLOYEE_NAME_ASC}
              orderDesc={RequestOrder.EMPLOYEE_NAME_DESC}
              onOrderChange={(o) => setOrder(o)}
            />
            <TableHeaderCell
              title="Type"
              order={order}
              orderAsc={RequestOrder.VISIT_TYPE_ASC}
              orderDesc={RequestOrder.VISIT_TYPE_DESC}
              onOrderChange={(o) => setOrder(o)}
            />
            <TableHeaderCell
              title="Project"
              order={order}
              orderAsc={RequestOrder.JOB_NO_ASC}
              orderDesc={RequestOrder.JOB_NO_DESC}
              onOrderChange={(o) => setOrder(o)}
            />
            <TableHeaderCell
              title="Entry Date/Time"
              order={order}
              orderAsc={RequestOrder.ENTRY_DATE_TIME_ASC}
              orderDesc={RequestOrder.ENTRY_DATE_TIME_DESC}
              onOrderChange={(o) => setOrder(o)}
            />
            <TableHeaderCell
              title="Exit Date/Time"
              order={order}
              orderAsc={RequestOrder.EXIT_DATE_TIME_ASC}
              orderDesc={RequestOrder.EXIT_DATE_TIME_DESC}
              onOrderChange={(o) => setOrder(o)}
            />
            <TableHeaderCell
              title="Visiting Company"
              order={order}
              orderAsc={RequestOrder.GUEST_COMPANY_ASC}
              orderDesc={RequestOrder.GUEST_COMPANY_DESC}
              onOrderChange={(o) => setOrder(o)}
            />
            <TableCell>Guest Count</TableCell>
            <TableCell>Badges</TableCell>
            <TableCell>Wi-Fi</TableCell>
            <TableCell>Notes</TableCell>
          </TableRow>
          <TableRow key="filterrow">
            <TableCell />
            <TableCell>
              <Tooltip title="Clear filters">
                <IconButton size="small" color="primary" onClick={clearFilters}>
                  <ClearAllIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell />
            <TableFilterCell
              value={employeeNameFilter}
              onChange={(value) => {
                setEmployeeNameFilter(value);
                setPage(0);
              }}
            />
            <TableCell>
              <FormControl fullWidth size="small" variant="outlined">
                <Select
                  fullWidth
                  value={visitTypeFilter}
                  onChange={(e) => {
                    setVisitTypeFilter(e.target.value as string);
                    setPage(0);
                  }}
                >
                  <MenuItem value="">--</MenuItem>
                  {visitTypes.map((visitType) => (
                    <MenuItem
                      key={visitType.visitId}
                      value={visitType.visitType}
                    >
                      {visitType.visitType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TableCell>
            <TableCell>
              <FormControl fullWidth size="small" variant="outlined">
                <Select
                  fullWidth
                  value={jobNoFilter}
                  onChange={(e) => {
                    setJobNoFilter(e.target.value as string);
                    setPage(0);
                  }}
                >
                  <MenuItem value="">--</MenuItem>
                  {projects.map((project) => (
                    <MenuItem key={project.projNo} value={project.projNo}>
                      {project.projNo + " " + project.projName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TableCell>
            <TableDateCell
              from={entryDateFrom}
              to={entryDateTo}
              onFromChange={(v) => {
                setEntryDateFrom(v);
                setPage(0);
              }}
              onToChange={(v) => {
                setEntryDateTo(v);
                setPage(0);
              }}
            />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={13}>
                <LinearProgress />
              </TableCell>
            </TableRow>
          )}
          {!isLoading && errorMessage && (
            <TableRow>
              <TableCell colSpan={13}>
                <Alert variant="outlined" color="error">
                  {errorMessage}
                </Alert>
              </TableCell>
            </TableRow>
          )}
          {!isLoading && !errorMessage && count === 0 && (
            <TableRow>
              <TableCell colSpan={13}>
                <Alert variant="outlined" color="info">
                  No Visitor Requests Available
                </Alert>
              </TableCell>
            </TableRow>
          )}
          {requests.map((row) => (
            <TableRow
              key={row.requestNumber}
              style={{
                backgroundColor: row.isExpired ? "#cbcbcb" : undefined,
                opacity: row.isCompleted ? 0.5 : undefined,
              }}
            >
              <TableCell>
                {row.isExpired && (
                  <Tooltip title="Request expired - 1 or more badges are not returned">
                    <WarningIcon
                      color="error"
                      fontSize="small"
                      style={{ verticalAlign: "middle" }}
                    />
                  </Tooltip>
                )}
                {row.isCompleted && (
                  <Tooltip title="All badges returned">
                    <AssignmentTurnedInIcon
                      color="primary"
                      fontSize="small"
                      style={{ verticalAlign: "middle" }}
                    />
                  </Tooltip>
                )}
              </TableCell>
              <TableCell>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => handleEditButtonClick(row.requestNumber)}
                >
                  {row.canUpdate ? (
                    <EditIcon fontSize="small" />
                  ) : (
                    <VisibilityIcon fontSize="small" />
                  )}
                </IconButton>
                {row.canUpdate && (
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => handleDeleteButtonClick(row.requestNumber)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                )}
              </TableCell>
              <TableCell>
                <Typography variant="caption">{row.requestNumber}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">{row.employeeName}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">{row.visitType}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">{row.jobNo}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {formatDate(row.entryDateTime)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {formatDate(row.exitDateTime)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">{row.guestCompany}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">{row.guests.length}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {row.guests
                    .map((x) => x.badgeId)
                    .filter((x) => !!x)
                    .join(", ")}
                </Typography>
              </TableCell>
              <TableCell>
                {row.wifiRequired && (
                  <WifiIcon
                    fontSize="small"
                    style={{ verticalAlign: "middle", color: green[500] }}
                  />
                )}
              </TableCell>
              <TableCell
                style={{
                  maxWidth: "10px",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                <Typography variant="caption">{row.notes}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter style={{ backgroundColor: grey[100] }}>
          <TableRow>
            <TableCell colSpan={13}>
              <Box display="flex" alignItems="center">
                {!props.archive && (
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={handleCreateButtonClick}
                  >
                    Add
                  </Button>
                )}
                <TablePagination
                  style={{ flexGrow: 1 }}
                  component="div"
                  showFirstButton
                  showLastButton
                  count={count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={(_, p) => setPage(p)}
                  onRowsPerPageChange={(e) => {
                    setRowsPerPage(parseInt(e.target.value, 10));
                    setPage(0);
                  }}
                />
              </Box>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
}
