import { Container } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { apiGetActiveUser } from "../api";
import LoanersTable from "../Components/LoanersTable";
import Panel from "../Components/Panel";
import RequestsTable from "../Components/RequestsTable";

export default function HomePage(): ReactElement {
  const [isUserAdmin, setIsUserAdmin] = useState(false);

  useEffect(() => {
    apiGetActiveUser()
      .then((data) => {
        setIsUserAdmin(data.persona === 1);
      })
      .catch(() => {
        setIsUserAdmin(false);
      });
  });

  return (
    <Container maxWidth={false} style={{ marginTop: 20, marginBottom: 20 }}>
      <Panel label="Visitors">
        <RequestsTable isUserAdmin={isUserAdmin} />
      </Panel>

      <Panel label="Loaners">
        <LoanersTable isUserAdmin={isUserAdmin} />
      </Panel>
    </Container>
  );
}
