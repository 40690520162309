import { Container } from "@mui/material";
import { ReactElement } from "react";
import LoanersTable from "../Components/LoanersTable";
import Panel from "../Components/Panel";
import RequestsTable from "../Components/RequestsTable";

export default function ArchivePage(): ReactElement {
  return (
    <Container maxWidth={false} style={{ marginTop: 20, marginBottom: 20 }}>
      <Panel label="Visitors Archive">
        <RequestsTable archive isUserAdmin={false} />
      </Panel>

      <Panel label="Loaners Archive">
        <LoanersTable archive isUserAdmin={false} />
      </Panel>
    </Container>
  );
}
