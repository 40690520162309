import AddIcon from "@mui/icons-material/Add";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { ReactElement, useEffect, useState } from "react";
import {
  apiGetCards,
  apiGetEmployees,
  apiGetLoanerBadgeCount,
  apiGetLoanerBadges,
} from "../api";
import LoanerBadgeDialog from "../Dialogs/LoanerBadgeDialog";
import {
  Card,
  CardType,
  Employee,
  LoanerBadge,
  LoanerBadgeOrder,
} from "../types";
import { formatDateOnly } from "../utils";
import TableDateCell from "./TableDateCell";
import TableHeaderCell from "./TableHeaderCell";

interface LoanersTableProps {
  archive?: boolean;
  isUserAdmin: boolean;
}

export default function LoanersTable(props: LoanersTableProps): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [employees, setEmployees] = useState<Employee[]>([]);
  const [cards, setCards] = useState<Card[]>([]);

  const [employeeFilter, setEmployeeFilter] = useState<Employee | null>(null);
  const [cardFilter, setCardFilter] = useState<Card | null>(null);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [order, setOrder] = useState<LoanerBadgeOrder>(
    props.archive ? LoanerBadgeOrder.DATE_DESC : LoanerBadgeOrder.DATE_ASC,
  );

  const [loaners, setLoaners] = useState<LoanerBadge[]>([]);

  const [selectedLoanerId, setSelectedLoanerId] = useState(0);

  const [isCreateEditDialogOpen, setIsCreateEditDialogOpen] = useState(false);

  useEffect(() => {
    apiGetCards(CardType.LOANER)
      .then((data) => {
        setCards(data);
      })
      .catch(() => {
        setCards([]);
      });

    apiGetEmployees()
      .then((data) => {
        setEmployees(data);
      })
      .catch(() => {
        setEmployees([]);
      });
  }, []);

  useEffect(() => {
    if (isCreateEditDialogOpen) return;

    apiGetLoanerBadgeCount({
      isActive: !props.archive,
      employeeCodeMatches: employeeFilter?.emp_Code,
      badgeNumberMatches: cardFilter?.cardId,
      dateFrom: dateFrom,
      dateTo: dateTo,
    })
      .then((data) => {
        setCount(data);
      })
      .catch((e) => {
        setCount(0);
      });
  }, [
    props.archive,
    employeeFilter,
    cardFilter,
    dateFrom,
    dateTo,
    isCreateEditDialogOpen,
  ]);

  useEffect(() => {
    if (isCreateEditDialogOpen) return;

    setIsLoading(true);
    apiGetLoanerBadges(
      {
        isActive: !props.archive,
        employeeCodeMatches: employeeFilter?.emp_Code,
        badgeNumberMatches: cardFilter?.cardId,
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
      order,
      page * rowsPerPage,
      rowsPerPage,
    )
      .then((data) => {
        setIsLoading(false);
        setErrorMessage("");
        setLoaners(data);
      })
      .catch((e) => {
        setIsLoading(false);
        setErrorMessage(e.message);
        setLoaners([]);
      });
  }, [
    props.archive,
    employeeFilter,
    cardFilter,
    dateFrom,
    dateTo,
    order,
    page,
    rowsPerPage,
    isCreateEditDialogOpen,
  ]);

  const clearFilters = () => {
    setEmployeeFilter(null);
    setCardFilter(null);
    setDateFrom("");
    setDateTo("");
  };

  const handleCreateButtonClick = () => {
    setSelectedLoanerId(0);
    setIsCreateEditDialogOpen(true);
  };

  const handleEditButtonClick = (requestNumber: number) => {
    setSelectedLoanerId(requestNumber);
    setIsCreateEditDialogOpen(true);
  };

  return (
    <>
      <LoanerBadgeDialog
        badgeId={selectedLoanerId}
        open={isCreateEditDialogOpen}
        onClose={() => setIsCreateEditDialogOpen(false)}
      />
      <Table size="small">
        <TableHead style={{ backgroundColor: grey[100] }}>
          <TableRow key="headerrow">
            <TableCell />
            <TableCell>Actions</TableCell>
            <TableHeaderCell
              title="ID"
              order={order}
              orderAsc={LoanerBadgeOrder.REQUEST_NUMBER_ASC}
              orderDesc={LoanerBadgeOrder.REQUEST_NUMBER_DESC}
              onOrderChange={(o) => setOrder(o)}
            />
            <TableHeaderCell
              title="Employee"
              order={order}
              orderAsc={LoanerBadgeOrder.EMPLOYEE_CODE_ASC}
              orderDesc={LoanerBadgeOrder.EMPLOYEE_CODE_DESC}
              onOrderChange={(o) => setOrder(o)}
            />
            <TableHeaderCell
              title="Badge Number"
              order={order}
              orderAsc={LoanerBadgeOrder.BADGE_CODE_ASC}
              orderDesc={LoanerBadgeOrder.BADGE_CODE_DESC}
              onOrderChange={(o) => setOrder(o)}
            />
            <TableHeaderCell
              title="Date"
              order={order}
              orderAsc={LoanerBadgeOrder.DATE_ASC}
              orderDesc={LoanerBadgeOrder.DATE_DESC}
              onOrderChange={(o) => setOrder(o)}
            />
          </TableRow>
          <TableRow key="filterrow">
            <TableCell />
            <TableCell>
              <Tooltip title="Clear filters">
                <IconButton size="small" color="primary" onClick={clearFilters}>
                  <ClearAllIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell />
            <TableCell>
              <Autocomplete
                size="small"
                options={employees}
                value={employeeFilter}
                onChange={(_, value) => {
                  setEmployeeFilter(value);
                  setPage(0);
                }}
                getOptionLabel={(option) =>
                  option.emp_Code + " " + option.emp_Name
                }
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
              />
            </TableCell>
            <TableCell>
              <Autocomplete
                size="small"
                options={cards}
                value={cardFilter}
                onChange={(_, value) => {
                  setCardFilter(value);
                  setPage(0);
                }}
                getOptionLabel={(option) => option.cardName}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
              />
            </TableCell>
            <TableDateCell
              from={dateFrom}
              to={dateTo}
              onFromChange={(v) => {
                setDateFrom(v);
                setPage(0);
              }}
              onToChange={(v) => {
                setDateTo(v);
                setPage(0);
              }}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={6}>
                <LinearProgress />
              </TableCell>
            </TableRow>
          )}
          {!isLoading && errorMessage && (
            <TableRow>
              <TableCell colSpan={6}>
                <Alert variant="outlined" color="error">
                  {errorMessage}
                </Alert>
              </TableCell>
            </TableRow>
          )}
          {!isLoading && !errorMessage && count === 0 && (
            <TableRow>
              <TableCell colSpan={6}>
                <Alert variant="outlined" color="info">
                  No Loaner Badges Available
                </Alert>
              </TableCell>
            </TableRow>
          )}
          {loaners.map((row) => (
            <TableRow
              key={row.requestNumber}
              style={{
                backgroundColor: row.isExpired ? "#cbcbcb" : undefined,
                opacity: row.isCompleted ? 0.5 : undefined,
              }}
            >
              <TableCell>
                {row.isExpired && (
                  <Tooltip title="Request expired - badge not returned">
                    <WarningIcon
                      color="error"
                      fontSize="small"
                      style={{ verticalAlign: "middle" }}
                    />
                  </Tooltip>
                )}
                {row.isCompleted && (
                  <Tooltip title="Badge returned">
                    <AssignmentTurnedInIcon
                      color="primary"
                      fontSize="small"
                      style={{ verticalAlign: "middle" }}
                    />
                  </Tooltip>
                )}
              </TableCell>
              <TableCell>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => handleEditButtonClick(row.requestNumber)}
                >
                  {row.canUpdate ? (
                    <EditIcon fontSize="small" />
                  ) : (
                    <VisibilityIcon fontSize="small" />
                  )}
                </IconButton>
              </TableCell>
              <TableCell>
                <Typography variant="caption">{row.requestNumber}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {row.employeeCode + " " + row.employeeName}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">{row.badgeCode}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {formatDateOnly(row.date)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter style={{ backgroundColor: grey[100] }}>
          <TableRow>
            <TableCell colSpan={6}>
              <Box display="flex" alignItems="center">
                {!props.archive && props.isUserAdmin && (
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={handleCreateButtonClick}
                  >
                    Add
                  </Button>
                )}
                <TablePagination
                  style={{ flexGrow: 1 }}
                  component="div"
                  showFirstButton
                  showLastButton
                  count={count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={(_, p) => setPage(p)}
                  onRowsPerPageChange={(e) => {
                    setRowsPerPage(parseInt(e.target.value, 10));
                    setPage(0);
                  }}
                />
              </Box>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
}
