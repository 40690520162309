export interface ActiveUser {
  email: string;
  name: string;
  persona: number;
  location: string;
}

export interface Card {
  cardId: string;
  cardName: string;
  cardType?: CardType;
  cardAllocations?: CardAllocation[];
}

export enum CardType {
  EMPLOYEE = "EMPLOYEE",
  LOANER = "LOANER",
  SERVICE = "SERVICE",
  STUDENT = "STUDENT",
  VISITOR = "VISITOR",
}

export interface CardAllocation {
  requestNumber: number;
  startDateTime?: string;
  endDateTime?: string;
}

export interface Employee {
  emp_Code: string;
  emp_Name: string;
  emp_Email: string;
  active?: boolean;
  dvsnName: string;
}

export interface Location {
  locationName: string;
}

export interface Project {
  projNo: string;
  projName: string;
}

export interface VisitType {
  visitId: number;
  visitType: string;
  cardType?: CardType;
}

export enum RequestOrder {
  REQUEST_NUMBER_ASC = "REQUEST_NUMBER_ASC",
  REQUEST_NUMBER_DESC = "REQUEST_NUMBER_DESC",
  EMPLOYEE_NAME_ASC = "EMPLOYEE_NAME_ASC",
  EMPLOYEE_NAME_DESC = "EMPLOYEE_NAME_DESC",
  VISIT_TYPE_ASC = "VISIT_TYPE_ASC",
  VISIT_TYPE_DESC = "VISIT_TYPE_DESC",
  JOB_NO_ASC = "JOB_NO_ASC",
  JOB_NO_DESC = "JOB_NO_DESC",
  GUEST_COMPANY_ASC = "GUEST_COMPANY_ASC",
  GUEST_COMPANY_DESC = "GUEST_COMPANY_DESC",
  ENTRY_DATE_TIME_ASC = "ENTRY_DATE_TIME_ASC",
  ENTRY_DATE_TIME_DESC = "ENTRY_DATE_TIME_DESC",
  EXIT_DATE_TIME_ASC = "EXIT_DATE_TIME_ASC",
  EXIT_DATE_TIME_DESC = "EXIT_DATE_TIME_DESC",
}

export interface Request extends RequestData {
  requestNumber: number;
  requestedBy: string;
  employeeName: string;
  isActive: boolean;
  isExpired: boolean;
  isCompleted: boolean;
  canAssignBadges: boolean;
  canUpdate: boolean;
}

export interface RequestData {
  location: string;
  visitType: string;
  jobNo: string;
  guestCompany: string;
  entryDateTime: string;
  exitDateTime: string;
  workingSpace: string;
  wifiRequired: boolean;
  wifiDetails: string;
  noBadgeRequired: boolean;
  noBadgeJustification: string;
  notes: string;
  guests: Guest[];
}

export interface Guest {
  id: number;
  name: string;
  badgeId: string;
  badgeReturned: boolean;
}

export enum LoanerBadgeOrder {
  REQUEST_NUMBER_ASC = "REQUEST_NUMBER_ASC",
  REQUEST_NUMBER_DESC = "REQUEST_NUMBER_DESC",
  EMPLOYEE_CODE_ASC = "EMPLOYEE_CODE_ASC",
  EMPLOYEE_CODE_DESC = "EMPLOYEE_CODE_DESC",
  BADGE_CODE_ASC = "BADGE_CODE_ASC",
  BADGE_CODE_DESC = "BADGE_CODE_DESC",
  DATE_ASC = "DATE_ASC",
  DATE_DESC = "DATE_DESC",
}

export interface LoanerBadge extends LoanerBadgeData {
  requestNumber: number;
  employeeName: string;
  isActive: boolean;
  isExpired: boolean;
  isCompleted: boolean;
  canUpdate: boolean;
}

export interface LoanerBadgeData {
  date: string;
  employeeCode: string;
  badgeCode: string;
  badgeReturned: boolean;
}

export interface Persona {
  id: number;
  name: string;
}

export interface User extends UserData {
  id: number;
}

export interface UserData {
  name: string;
  email: string;
  persona: string;
}
