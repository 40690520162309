import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ReactElement } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { msalInstance } from "../auth";
import ArchivePage from "../Pages/ArchivePage";
import HomePage from "../Pages/HomePage";
import LoginPage from "../Pages/LoginPage";
import UsersPage from "../Pages/UsersPage";
import theme from "../theme";
import Header from "./Header";

export default function Application(): ReactElement {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MsalProvider instance={msalInstance}>
        <BrowserRouter>
          <Header onLogoutClick={() => msalInstance.logoutRedirect()} />

          <UnauthenticatedTemplate>
            <LoginPage onLoginClick={() => msalInstance.loginRedirect()} />
          </UnauthenticatedTemplate>

          <AuthenticatedTemplate>
            <Routes>
              <Route path="/" element={<HomePage key="home" />} />
              <Route path="/archive" element={<ArchivePage key="archive" />} />
              <Route path="/users" element={<UsersPage key="users" />} />
            </Routes>
          </AuthenticatedTemplate>
        </BrowserRouter>
      </MsalProvider>
    </ThemeProvider>
  );
}
