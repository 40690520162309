import { getAccessToken } from "./auth";
import {
  ActiveUser,
  Card,
  CardType,
  Employee,
  LoanerBadge,
  LoanerBadgeData,
  LoanerBadgeOrder,
  Location,
  Persona,
  Project,
  Request,
  RequestData,
  RequestOrder,
  User,
  UserData,
  VisitType,
} from "./types";

const ApiPrefix: string = process.env.REACT_APP_API_URL_PREFIX || "";

export interface ProblemDetails {
  title: string;
  status: number;
  errors: Record<string, string>;
}

type Params = Record<string, string | string[]>;

function formUrl(path: string, params?: Params): string {
  const url: URL = new URL(path, ApiPrefix);
  for (const name in params) {
    const value = params[name];
    if (typeof value === "string") {
      url.searchParams.append(name, value);
    } else {
      for (const item of value) {
        url.searchParams.append(name, item);
      }
    }
  }
  return url.toString();
}

interface ApiCallParams {
  method: string;
  path: string;
  body?: object;
  params?: Params;
}

async function apiCallInner(params: ApiCallParams): Promise<Response> {
  const url: string = formUrl(params.path, params.params);
  const accessToken: string = await getAccessToken();
  const response: Response = await fetch(url, {
    method: params.method,
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params.body),
  });
  return response;
}

export async function apiGetActiveUser(): Promise<ActiveUser> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "activeuser",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: ActiveUser = await response.json();
  return data;
}

export async function apiGetCards(type?: CardType): Promise<Card[]> {
  const params: Params = {};
  if (type) {
    params["type"] = type;
  }
  const response: Response = await apiCallInner({
    method: "GET",
    path: "cards",
    params: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Card[] = await response.json();
  return data;
}

export async function apiGetEmployees(): Promise<Employee[]> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "employees",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Employee[] = await response.json();
  return data;
}

export async function apiGetLocations(): Promise<Location[]> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "requestlocation",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Location[] = await response.json();
  return data;
}

export async function apiGetProjects(): Promise<Project[]> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "project",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Project[] = await response.json();
  return data;
}

export async function apiGetVisitTypes(): Promise<VisitType[]> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "visittype",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: VisitType[] = await response.json();
  return data;
}

export interface RequestFilter {
  isActive?: boolean;
  employeeNameContains?: string;
  visitTypeMatches?: string;
  jobNoMatches?: string;
  entryDateTimeFrom?: string;
  entryDateTimeTo?: string;
}

export async function apiGetRequestCount(
  filter?: RequestFilter,
): Promise<number> {
  const params: Params = {};
  if (typeof filter?.isActive === "boolean") {
    params["isActive"] = filter.isActive ? "true" : "false";
  }
  if (typeof filter?.employeeNameContains === "string") {
    params["employeeNameContains"] = filter.employeeNameContains;
  }
  if (typeof filter?.visitTypeMatches === "string") {
    params["visitTypeMatches"] = filter.visitTypeMatches;
  }
  if (typeof filter?.jobNoMatches === "string") {
    params["jobNoMatches"] = filter.jobNoMatches;
  }
  if (filter?.entryDateTimeFrom) {
    params["entryDateTimeFrom"] = filter.entryDateTimeFrom;
  }
  if (filter?.entryDateTimeTo) {
    params["entryDateTimeTo"] = filter.entryDateTimeTo;
  }
  const response: Response = await apiCallInner({
    method: "GET",
    path: "requests/count",
    params: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: string = await response.text();
  return parseInt(data, 10);
}

export async function apiGetRequests(
  filter?: RequestFilter,
  order?: RequestOrder,
  skip?: number,
  take?: number,
): Promise<Request[]> {
  const params: Params = {};
  if (typeof filter?.isActive === "boolean") {
    params["isActive"] = filter.isActive ? "true" : "false";
  }
  if (typeof filter?.employeeNameContains === "string") {
    params["employeeNameContains"] = filter.employeeNameContains;
  }
  if (typeof filter?.visitTypeMatches === "string") {
    params["visitTypeMatches"] = filter.visitTypeMatches;
  }
  if (typeof filter?.jobNoMatches === "string") {
    params["jobNoMatches"] = filter.jobNoMatches;
  }
  if (filter?.entryDateTimeFrom) {
    params["entryDateTimeFrom"] = filter.entryDateTimeFrom;
  }
  if (filter?.entryDateTimeTo) {
    params["entryDateTimeTo"] = filter.entryDateTimeTo;
  }
  if (order) {
    params["order"] = order.toString();
  }
  if (skip) {
    params["skip"] = skip.toString();
  }
  if (take) {
    params["take"] = take.toString();
  }
  const response: Response = await apiCallInner({
    method: "GET",
    path: "requests",
    params: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Request[] = await response.json();
  return data;
}

export async function apiGetRequest(requestNumber: number): Promise<Request> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "requests/" + requestNumber,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Request = await response.json();
  return data;
}

export async function apiCreateRequest(
  requestData: RequestData,
): Promise<ProblemDetails | null> {
  const response: Response = await apiCallInner({
    method: "POST",
    path: "requests",
    body: requestData,
  });
  if (response.status === 400) {
    const data: ProblemDetails = await response.json();
    return data;
  } else if (!response.ok) {
    const text = await response.text();
    throw Error(text ? text : response.statusText);
  }
  return null;
}

export async function apiUpdateRequest(
  requestNumber: number,
  requestData: RequestData,
): Promise<ProblemDetails | null> {
  const response: Response = await apiCallInner({
    method: "PUT",
    path: "requests/" + requestNumber,
    body: requestData,
  });
  if (response.status === 400) {
    const data: ProblemDetails = await response.json();
    return data;
  } else if (!response.ok) {
    const text = await response.text();
    throw Error(text ? text : response.statusText);
  }
  return null;
}

export async function apiDeleteRequest(requestNumber: number): Promise<void> {
  const response: Response = await apiCallInner({
    method: "DELETE",
    path: "requests/" + requestNumber,
  });
  if (!response.ok) throw Error(response.statusText);
}

interface LoanerBadgeFilter {
  isActive?: boolean;
  dateFrom?: string;
  dateTo?: string;
  employeeCodeMatches?: string;
  badgeNumberMatches?: string;
}

export async function apiGetLoanerBadgeCount(
  filter: LoanerBadgeFilter,
): Promise<number> {
  const params: Params = {};
  if (typeof filter?.isActive === "boolean") {
    params["isActive"] = filter.isActive ? "true" : "false";
  }
  if (filter?.dateFrom) {
    params["dateFrom"] = filter.dateFrom;
  }
  if (filter?.dateTo) {
    params["dateTo"] = filter.dateTo;
  }
  if (typeof filter?.employeeCodeMatches === "string") {
    params["employeeCodeMatches"] = filter.employeeCodeMatches;
  }
  if (typeof filter?.badgeNumberMatches === "string") {
    params["badgeNumberMatches"] = filter.badgeNumberMatches;
  }
  const response: Response = await apiCallInner({
    method: "GET",
    path: "loaners/count",
    params: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: string = await response.text();
  return parseInt(data, 10);
}

export async function apiGetLoanerBadges(
  filter: LoanerBadgeFilter,
  order?: LoanerBadgeOrder,
  skip?: number,
  take?: number,
): Promise<LoanerBadge[]> {
  const params: Params = {};
  if (typeof filter?.isActive === "boolean") {
    params["isActive"] = filter.isActive ? "true" : "false";
  }
  if (filter?.dateFrom) {
    params["dateFrom"] = filter.dateFrom;
  }
  if (filter?.dateTo) {
    params["dateTo"] = filter.dateTo;
  }
  if (typeof filter?.employeeCodeMatches === "string") {
    params["employeeCodeMatches"] = filter.employeeCodeMatches;
  }
  if (typeof filter?.badgeNumberMatches === "string") {
    params["badgeNumberMatches"] = filter.badgeNumberMatches;
  }
  if (order) {
    params["order"] = order.toString();
  }
  if (skip) {
    params["skip"] = skip.toString();
  }
  if (take) {
    params["take"] = take.toString();
  }
  const response: Response = await apiCallInner({
    method: "GET",
    path: "loaners",
    params: params,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: LoanerBadge[] = await response.json();
  return data;
}

export async function apiGetLoanerBadge(
  requestNumber: number,
): Promise<LoanerBadge> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "loaners/" + requestNumber,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: LoanerBadge = await response.json();
  return data;
}

export async function apiCreateLoanerBadge(
  data: LoanerBadgeData,
): Promise<ProblemDetails | null> {
  const response: Response = await apiCallInner({
    method: "POST",
    path: "loaners",
    body: data,
  });
  if (response.status === 400) {
    const data: ProblemDetails = await response.json();
    return data;
  } else if (!response.ok) {
    const text = await response.text();
    throw Error(text ? text : response.statusText);
  }
  return null;
}

export async function apiUpdateLoanerBadge(
  requestNumber: number,
  data: LoanerBadgeData,
): Promise<ProblemDetails | null> {
  const response: Response = await apiCallInner({
    method: "PUT",
    path: "loaners/" + requestNumber,
    body: data,
  });
  if (response.status === 400) {
    const data: ProblemDetails = await response.json();
    return data;
  } else if (!response.ok) {
    const text = await response.text();
    throw Error(text ? text : response.statusText);
  }
  return null;
}

export async function apiGetPersonas(): Promise<Persona[]> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "users/personas",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Persona[] = await response.json();
  return data;
}

export async function apiGetUsers(): Promise<User[]> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "users",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: User[] = await response.json();
  return data;
}

export async function apiGetUser(userId: number): Promise<User> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "users/" + userId,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: User = await response.json();
  return data;
}

export async function apiCreateUser(
  userData: UserData,
): Promise<ProblemDetails | null> {
  const response: Response = await apiCallInner({
    method: "POST",
    path: "users",
    body: userData,
  });
  if (response.status === 400) {
    const data: ProblemDetails = await response.json();
    return data;
  } else if (!response.ok) {
    const text = await response.text();
    throw Error(text ? text : response.statusText);
  }
  return null;
}

export async function apiUpdateUser(
  userId: number,
  userData: UserData,
): Promise<ProblemDetails | null> {
  const response: Response = await apiCallInner({
    method: "PUT",
    path: "users/" + userId,
    body: userData,
  });
  if (response.status === 400) {
    const data: ProblemDetails = await response.json();
    return data;
  } else if (!response.ok) {
    const text = await response.text();
    throw Error(text ? text : response.statusText);
  }
  return null;
}

export async function apiDeleteUser(userId: number): Promise<void> {
  const response: Response = await apiCallInner({
    method: "DELETE",
    path: "users/" + userId,
  });
  if (!response.ok) throw Error(response.statusText);
}
