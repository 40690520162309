import {
  Alert,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import {
  apiCreateLoanerBadge,
  apiGetCards,
  apiGetEmployees,
  apiGetLoanerBadge,
  apiUpdateLoanerBadge,
} from "../api";
import { Card, CardType, Employee, LoanerBadgeData } from "../types";
import {
  dateToISODate,
  dateToLocalDateTime,
  localDateTimeToDate,
} from "../utils";

interface LoanerBadgeDialogProps {
  badgeId: number;
  open: boolean;
  onClose: (confirm: boolean) => void;
}

export default function LoanerBadgeDialog(
  props: LoanerBadgeDialogProps,
): ReactElement {
  const [errorMessage, setErrorMessage] = useState("");

  const [cards, setCards] = useState<Card[]>([]);
  const [employees, setEmployees] = useState<Employee[]>([]);

  const [date, setDate] = useState("");
  const [cardNo, setCardNo] = useState("");
  const [card, setCard] = useState<Card | null>(null);
  const [employeeNo, setEmployeeNo] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [employee, setEmployee] = useState<Employee | null>(null);
  const [badgeReturned, setBadgeReturned] = useState(false);

  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    apiGetCards(CardType.LOANER)
      .then((data) => setCards(data))
      .catch(() => setCards([]));

    apiGetEmployees()
      .then((data) => setEmployees(data))
      .catch(() => setEmployees([]));
  }, []);

  useEffect(() => {
    if (props.open && props.badgeId) {
      apiGetLoanerBadge(props.badgeId)
        .then((data) => {
          setDate(dateToLocalDateTime(data.date));
          setCardNo(data.badgeCode);
          setEmployeeNo(data.employeeCode);
          setEmployeeName(data.employeeName);
          setBadgeReturned(data.badgeReturned);
          setIsEditable(data.canUpdate);
        })
        .catch((e) => {
          setErrorMessage(e.message);
        });
    } else {
      setDate(new Date().toISOString());
      setCardNo("");
      setEmployeeNo("");
      setEmployeeName("");
      setBadgeReturned(false);
      setIsEditable(true);

      setErrorMessage("");
    }
  }, [props.badgeId, props.open]);

  useEffect(() => {
    setCard(cards.find((x) => x.cardId === cardNo) || null);
  }, [cards, cardNo]);

  useEffect(() => {
    const found = employees.find((x) => x.emp_Code === employeeNo);
    if (found) {
      setEmployee(found);
    } else if (employeeNo && employeeName) {
      const missing: Employee = {
        emp_Code: employeeNo,
        emp_Name: employeeName,
        emp_Email: "",
        dvsnName: "",
      };
      setEmployees(employees.concat([missing]));
      setEmployee(missing);
    } else {
      setEmployee(null);
    }
  }, [employees, employeeNo, employeeName]);

  const save = () => {
    const data: LoanerBadgeData = {
      date: localDateTimeToDate(date),
      employeeCode: employeeNo,
      badgeCode: cardNo,
      badgeReturned: badgeReturned,
    };
    if (props.badgeId) {
      apiUpdateLoanerBadge(props.badgeId, data)
        .then((result) => {
          if (result) {
            setErrorMessage(JSON.stringify(result.errors));
          } else {
            props.onClose(true);
          }
        })
        .catch((e) => {
          setErrorMessage(e.message);
        });
    } else {
      apiCreateLoanerBadge(data)
        .then((result) => {
          if (result) {
            setErrorMessage(JSON.stringify(result.errors));
          } else {
            props.onClose(true);
          }
        })
        .catch((e) => {
          setErrorMessage(e.message);
        });
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth={true} open={props.open}>
      <DialogTitle>
        {props.badgeId ? "Loaner " + props.badgeId : "New Loaner"}
      </DialogTitle>
      <DialogContent dividers>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <TextField
          fullWidth
          size="small"
          margin="normal"
          type="date"
          label="Date"
          InputLabelProps={{ shrink: true }}
          disabled={!isEditable}
          value={dateToISODate(date)}
          onChange={(e) => setDate(e.target.value)}
        />
        <Autocomplete
          size="small"
          disabled={!isEditable}
          value={employee}
          options={employees}
          getOptionLabel={(option) => option.emp_Code + " " + option.emp_Name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Employee"
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
          )}
          onChange={(_, value) => setEmployeeNo(value?.emp_Code || "")}
        />
        <Autocomplete
          size="small"
          disabled={!isEditable}
          value={card}
          options={cards}
          getOptionLabel={(option) => option.cardName || "(none)"}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Badge"
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
          )}
          onChange={(_, value) => setCardNo(value?.cardId || "")}
        />
        <FormControlLabel
          label="Badge Returned"
          control={
            <Switch
              disabled={!isEditable}
              checked={badgeReturned}
              onChange={(e) => setBadgeReturned(e.target.checked)}
            />
          }
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={save} disabled={!isEditable}>
          Save
        </Button>
        <Button color="primary" onClick={() => props.onClose(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
